<template>
  <div
    class="map"
    ref="map"
    :data-gesture-handling-touch-content="touchContent"
    :data-gesture-handling-scroll-content="scrollContent"
  ></div>
</template>

<script>
import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";

import "leaflet/dist/leaflet.css";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

export default {
  data() {
    return {
      map: null
    };
  },
  computed: {
    touchContent() {
      return this.$t("map.touch");
    },
    scrollContent() {
      return navigator.platform.toUpperCase().indexOf("MAC") < 0
        ? this.$t("map.scroll")
        : this.$t("map.scrollMac");
    }
  },
  mounted() {
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);

    this.map = L.map(this.$refs.map, {
      center: [56.93342, 24.04333],
      zoom: 13,
      minZoom: 12,
      maxZoom: 18,
      gestureHandling: true,
      gestureHandlingText: {
        touch: this.$t("map.touch"),
        scroll: this.$t("map.scroll"),
        scrollMac: this.$t("map.scrollMac")
      }
    });

    L.tileLayer(
      "https://api.mapbox.com/styles/v1/{id}/tiles/256/{z}/{x}/{y}?access_token={accessToken}",
      {
        id: "tagree/ckqw0qgar2cem18qp4g1mjps7",
        accessToken:
          "pk.eyJ1IjoidGFncmVlIiwiYSI6ImNrcXcwYmlqdTBqeGQycG81dXljeGhqY3AifQ.1HYUT7l0hePwik1wdIFCpg"
      }
    ).addTo(this.map);
  }
};
</script>
