<template>
  <main class="app__page location">
    <div class="location__container">
      <h1 class="location__title title title--bb">
        {{ $t("pages.location.title") }}
      </h1>

      <div v-if="pageLocale" class="location__content-wrapper">
        <div class="location__bg-img bg-img"></div>
      </div>
    </div>

    <div class="location__map-wrapper">
      <LeafletMap class="location__map" />
    </div>

    <div v-if="pageLocale" class="location__content-wrapper">
      <div class="location__info">
        <div class="location__container location__container--df">
          <div class="location__img-wrap is-observable">
            <img
              class="location__img observe-target observe-80-bottom"
              src="/img/location/location1.jpg"
              alt="location1"
            />
          </div>

          <div class="location__info-blocks is-observable">
            <p class="location__info-main observe-target observe-80-bottom">
              {{ pageLocale.main_text }}
            </p>
            <p
              class="
                location__info-text
                observe-target observe-80-bottom
                delay-02
              "
            >
              {{ pageLocale.info[0] }}
            </p>
          </div>
        </div>
        <div class="location__semicircles"></div>
        <img
          class="location__bg-word"
          src="/img/location/sights.svg"
          alt="sights"
        />
      </div>
      <div class="location__info2">
        <div
          class="
            location__container
            location__container--df
            location__container--center
          "
        >
          <div class="location__info-blocks2 is-observable">
            <p class="location__info-main2 observe-target observe-80-bottom">
              {{ pageLocale.info[1] }}
            </p>
            <p
              class="
                location__info-text location__info-text--w330
                observe-target observe-80-bottom
                delay-02
              "
            >
              {{ pageLocale.info[2] }}
            </p>
            <p
              class="
                location__info-text location__info-text--w520
                observe-target observe-80-bottom
                delay-02
              "
            >
              {{ pageLocale.info[3] }}
            </p>
          </div>

          <div class="location__img2-wrap is-observable">
            <img
              class="location__img2 observe-target observe-80-bottom"
              src="/img/location/location2.jpg"
              alt="location2"
            />
          </div>
        </div>
        <div class="location__semicircles-right"></div>
      </div>
    </div>

    <div class="location__container-2500">
      <localized-link to="/developer" class="next-page">
        <span class="next-page__capiton">{{ $t("next") }}</span>
        <span class="next-page__name">
          <span class="next-page__title">
            {{ $t("pages.developer.title") }}
          </span>
          <span class="next-page__btn r-btn">
            <svg-icon
              width="14"
              height="10"
              name="arrow_right"
              class="btn-icon"
            />
          </span>
        </span>
      </localized-link>
    </div>
    <PageFooter />
  </main>
</template>

<script>
import { pageLocale } from "@/mixins/pageLocale.js";
import { observer } from "@/mixins/observer.js";
import LeafletMap from "@/components/LeafletMap";
import PageFooter from "@/components/PageFooter";
export default {
  name: "location",
  mixins: [pageLocale, observer],
  components: {
    LeafletMap,
    PageFooter
  }
};
</script>

<style lang="less">
.location {
  .padding-page-top;
  position: relative;
  overflow: hidden;
  &__container {
    .container;
    &--df {
      display: flex;
      @media @bw768 {
        flex-direction: column;
      }
    }
    &--center {
      justify-content: center;
      align-items: flex-end;
      @media @bw1510 {
        align-items: center;
      }
      @media @bw1170 {
        align-items: flex-end;
      }
    }
  }
  &__locations-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    width: 100%;
    @media @bw1170 {
      display: none;
    }
  }
  &__locations {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    @media @bw1510 {
      max-width: 620px;
    }
  }
  &__nearby {
    margin-right: 115px;
    @media @bw1510 {
      margin-right: 105px;
    }
  }
  &__nearby-word {
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    color: @white;
  }
  &__nearby-caption {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 11px;
    line-height: 1.2;
    color: @white_60;
  }
  &__location {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    width: 250px;
    @media @bw1510 {
      width: 310px;
    }
  }
  &__location-el {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 21px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    border: 1px solid @gold;
    background-color: transparent;
  }
  &__location-icon {
    margin: auto;
    color: @gold;
  }
  &__map-wrapper {
    position: relative;
    margin-bottom: 140px;
    width: 100%;
    height: 700px;
    z-index: 0;
    @media @bw1510 {
      margin-bottom: 120px;
    }
    @media @bw1170 {
      margin-bottom: 100px;
    }
    @media @bw768 {
      height: 660px;
      margin-bottom: 80px;
    }
    @media @bw400 {
      height: 500px;
    }
  }
  &__map {
    width: 100%;
    height: 100%;
  }
  &__info {
    position: relative;
    padding-bottom: 130px;
    @media @bw1510 {
      padding-bottom: 190px;
    }
    @media @bw1170 {
      padding-bottom: 150px;
    }
    @media @bw768 {
      margin-bottom: 50px;
      padding-bottom: 90px;
    }
    @media @bw400 {
      margin-bottom: 40px;
    }
  }
  &__info2 {
    position: relative;
    margin-bottom: 150px;
    @media @bw1510 {
      margin-top: 0;
    }
    @media @bw1170 {
      margin-bottom: 100px;
    }
  }
  &__img-wrap {
    margin-left: 130px;
    margin-top: 130px;
    margin-right: 115px;
    max-width: 467px;
    width: 100%;

    @media @bw1510 {
      margin-top: 150px;
      margin-right: 90px;
      margin-left: 0;
      max-width: 414px;
    }
    @media @bw1170 {
      margin-top: 130px;
      margin-right: 60px;
      max-width: 300px;
    }
    @media @bw768 {
      order: 2;
      margin-top: 0;
      margin-right: 0;
      max-width: 374px;
    }
    @media @bw400 {
      max-width: 100%;
    }
  }
  &__img {
    display: block;
    width: 100%;
    box-shadow: 0px 50px 100px rgba(51, 57, 66, 0.6);
  }
  &__info-blocks {
    box-sizing: border-box;
    max-width: 550px;
    width: 100%;
    @media @bw1510 {
      max-width: 470px;
    }
    @media @bw1170 {
      max-width: 350px;
    }
    @media @bw768 {
      margin-bottom: 40px;
      order: 1;
      align-self: flex-end;
      max-width: calc(100% - 70px);
    }
    @media @bw400 {
      max-width: 100%;
    }
  }
  &__info-main {
    margin: 0 0 40px;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    color: @white;
    @media @bw1170 {
      font-size: 24px;
    }
    @media @bw768 {
      font-weight: 500;
      font-size: 20px;
    }
    @media @bw400 {
      font-size: 18px;
    }
  }
  &__info-text {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
    color: @white_80;
    &--w330 {
      max-width: 330px;
      width: 100%;
      @media @bw768 {
        max-width: calc(100% - 70px);
      }
      @media @bw400 {
        max-width: 100%;
      }
    }

    &--w520 {
      margin-top: 40px;
      max-width: 520px;
      width: 100%;
      @media @bw1170 {
        max-width: 435px;
      }
      @media @bw768 {
        align-self: flex-start;
        margin-top: 20px;
      }
    }
  }
  &__info-blocks2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 130px;
    margin-right: 30px;
    @media @bw1510 {
      margin-left: 0px;
    }
    @media @bw1170 {
      margin-left: 0;
    }
    @media @bw768 {
      margin-right: 0;
      margin-bottom: 40px;
    }
  }
  &__bg-word {
    max-width: 830px;
    width: 100%;
    z-index: -1;
    position: absolute;
    right: 0;
    bottom: -40px;
    pointer-events: none;
    @media @bw1510 {
      bottom: -5px;
      max-width: 556px;
    }
    @media @bw1170 {
      bottom: 10px;
      max-width: 432px;
    }
    @media @bw768 {
      bottom: -15px;
      max-width: 325px;
    }
    @media @bw400 {
      bottom: -5px;
      max-width: 292px;
    }
  }
  &__img2-wrap {
    margin-right: 50px;
    max-width: 467px;
    width: 100%;
    @media @bw1510 {
      margin-right: 0px;
      max-width: 340px;
    }
    @media @bw1170 {
      max-width: 280px;
    }
    @media @bw768 {
      max-width: 374px;
    }
    @media @bw400 {
      max-width: 100%;
    }
  }
  &__img2 {
    display: block;
    width: 100%;
    box-shadow: 0px 50px 100px rgba(51, 57, 66, 0.6);
  }
  &__info-main2 {
    margin: 0 0 40px;
    max-width: 546px;
    width: 100%;
    font-weight: 700;
    font-size: 24px;
    line-height: 1.3;
    color: @white;
    @media @bw1170 {
      margin-bottom: 30px;
      max-width: 435px;
      font-weight: 500;
      font-size: 20px;
    }
    @media @bw768 {
      margin-bottom: 20px;
      max-width: 100%;
      font-size: 16px;
    }
  }
  &__next-page {
    .container;
  }
  &__semicircles {
    position: absolute;
    left: 0;
    top: 37%;
    width: 232px;
    height: 400px;
    overflow: hidden;
    z-index: -1;
    @media @bw1510 {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 16px;
      width: 380px;
      height: 380px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @white;
    }
    &::after {
      top: 0;
      right: 58px;
      border: 1px solid @gold;
    }
  }
  &__semicircles-right {
    position: absolute;
    bottom: 90px;
    right: 0;
    width: 190px;
    height: 345px;
    overflow: hidden;
    z-index: -1;
    @media @bw1510 {
      display: none;
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 58px;
      top: 18px;
      width: 307px;
      height: 307px;
      border-radius: 50%;
      background-color: transparent;
      border: 1px solid @white;
    }
    &::after {
      top: 0;
      left: 0;
      border: 1px solid @gold;
    }
  }
  &__container-2500 {
    .container-2500;
  }
}
</style>
